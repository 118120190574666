import { deAT, enGB, frCH, ro, sv, itCH, de } from 'date-fns/locale'

import type { AppLocale } from '@ngb-frontend/shared/types'
import type { Locale as DateFnsLocale } from 'date-fns/locale'

export const DEFAULT_LOCALE: AppLocale = 'en-GLOBAL'

// TODO: lazy load formatting locales
export const dateFnsLocales: Partial<Record<AppLocale, DateFnsLocale>> = {
  // Prefer country specific language locales (if they exist), else use one that has the correct language
  [DEFAULT_LOCALE]: enGB,
  'en-GB': enGB,
  'sv-SE': sv,
  'en-SE': enGB,
  'de-AT': deAT,
  'en-AT': enGB,
  'ro-RO': ro,
  'en-RO': enGB,
  'fr-CH': frCH,
  'de-CH': de,
  'it-CH': itCH,
  'en-CH': enGB,
}

export const DEFAULT_DATE_FNS_LOCALE = enGB
