import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useSWRConfig } from 'swr'

import { getFlowConfig } from '@ngb-frontend/features/flow-config'
import { useFlow } from '@ngb-frontend/flow'
import { useAppConfig, useContent } from '@ngb-frontend/shared/context'
import {
  ErrorVariants,
  Feature,
  SystemCode,
  type BasicFeatureStep,
  type QueryParams,
} from '@ngb-frontend/shared/types'
import {
  FullScreenLoader,
  StepLayout as Layout,
  boundaryErrors,
} from '@ngb-frontend/shared/ui'
import {
  getMnRDCCFlowDataFromBooking,
  getEoLDCCFlowDataFromBooking,
  getMnRFlowDataFromBooking,
  isDynamicStep,
  isQuery,
  omitNull,
  useBookingRequest,
  useBookingRequestHistory,
  useDriver,
  useQuery,
  useVehicle,
  isLPSystem,
} from '@ngb-frontend/shared/utils'

import type { GetServerSideProps, NextPage } from 'next'

export type PageProps = { query: QueryParams | null }

const Index: NextPage<PageProps> = () => {
  const c = useContent()
  const router = useRouter()
  const { mutate } = useSWRConfig()
  const config = useAppConfig()

  const query = useQuery()
  const isRoutingToAppointments = useRef(false)
  const {
    hasData,
    hasBeenChecked,
    isLoading: bookingHistoryLoading,
  } = useBookingRequestHistory(
    !query?.bookingId ? query?.vehicleIdentifier : undefined,
    undefined,
    query?.country,
  )

  const showExistingAppointments = hasData && !hasBeenChecked

  const {
    data: bookingData,
    error: bookingError,
    isLoading: bookingLoading,
  } = useBookingRequest(query?.bookingId)

  // Always refetch a passed booking on app start
  useEffect(() => {
    if (query?.bookingId)
      mutate([config.apiRoutes.fetchBooking, { bookingId: query?.bookingId }])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (showExistingAppointments && !isRoutingToAppointments.current) {
      router.push('/existing-appointment')
      isRoutingToAppointments.current = true
    }
  }, [router, showExistingAppointments])

  const pathname = window.location.pathname

  const preventReplaceUrl =
    (pathname.includes('summary') && window.location.search) ||
    isRoutingToAppointments.current

  const replaceUrl =
    (pathname === '/' || window.location.search) && !preventReplaceUrl
  const fConfig = getFlowConfig(query?.systemCode, query?.service)

  // The following routing logic is not put in useEffect to run before initial render
  if (query && replaceUrl) {
    router.replace(fConfig.steps[0].path, undefined, {
      shallow: true,
    })
  }

  const stepPath = React.useMemo(() => pathname.replace(/^\//, ''), [pathname])

  const {
    data: vehicle,
    isLoading: vehicleLoading,
    error: vehicleError,
  } = useVehicle({
    // Always pass an ilan and/or licensePlate to this hook call to enable app state overrides
    vehicleIdentifier: !isRoutingToAppointments.current
      ? query?.vehicleIdentifier
      : undefined,
    country: query?.country,
  })

  const { data: driver, isLoading: driverLoading } = useDriver(
    vehicle?.driverIdentifier || query?.licensePlate,
    query?.country,
  )

  const initialFlowData = useMemo(() => {
    const booking = bookingData?.[0]
    if (!booking) return []

    switch (fConfig.type) {
      case Feature.MnR:
        return getMnRFlowDataFromBooking(booking)
      case Feature.MnRDCC:
        return getMnRDCCFlowDataFromBooking(booking)
      case Feature.EoLDCC:
        return getEoLDCCFlowDataFromBooking(booking)
      default:
        return []
    }
  }, [bookingData, fConfig.type])

  // Get step
  const flowApi = useFlow(
    fConfig?.steps || [],
    stepPath,
    router,
    '',
    initialFlowData,
    bookingLoading,
  )
  const stepIndex = flowApi.stepIndex
  const activeStep = fConfig?.steps[stepIndex]
  const stepCount = fConfig?.steps.length
  const activeStepVariant =
    flowApi.stepVariant && isDynamicStep(activeStep, flowApi.stepVariant)
      ? Object.assign(activeStep, activeStep.variant[flowApi.stepVariant])
      : (activeStep as BasicFeatureStep<Feature>) // safe
  const Component = activeStepVariant?.component

  const onClose = useCallback(
    () => router.push(query?.closeUrl || ''),
    [query?.closeUrl, router],
  )

  const loadingCtx = bookingHistoryLoading || vehicleLoading || driverLoading

  useEffect(() => {
    if (
      stepPath &&
      !activeStep &&
      !isRoutingToAppointments.current &&
      stepPath !== query?.returnUrl &&
      stepPath !== query?.closeUrl
    ) {
      throw new Error(boundaryErrors.unknownPath)
    }
  }, [activeStep, query?.closeUrl, query?.returnUrl, stepPath])

  if (
    isRoutingToAppointments.current ||
    !activeStep ||
    bookingLoading ||
    loadingCtx
  ) {
    return <FullScreenLoader />
  }

  let ErrorVariant: ErrorVariants | null = null
  if (isLPSystem(query?.systemCode) && vehicleError) {
    ErrorVariant = Object.values(ErrorVariants).includes(vehicleError.info)
      ? vehicleError.info
      : ErrorVariants.Vehicle
  } else if (!bookingData && bookingError) {
    ErrorVariant = ErrorVariants.FetchBooking
  } else if (!driver) {
    ErrorVariant = ErrorVariants.Driver
  }

  if (ErrorVariant) {
    router.push(`/error/${ErrorVariant}`)
    return <FullScreenLoader />
  }

  return (
    <Layout
      // TODO: fallback to first step as active step (see useFlow.ts)
      navLabel={c(`${activeStepVariant.navLabel}`)}
      topNavLabel={c('navigationBar.topLabel', {
        variables: {
          currentStep: `${stepIndex + 1}`,
          numberOfSteps: `${stepCount}`,
        },
      })}
      title={activeStepVariant.title && c(activeStepVariant.title)}
      onStepBack={
        activeStepVariant.routeToBack
          ? router.back
          : flowApi?.hasPreviousStep
          ? flowApi.onPreviousStep
          : onClose
      }
      banner={
        activeStepVariant.banner && {
          ...activeStepVariant.banner,
          title: c(activeStepVariant.banner.title),
        }
      }
      progress={(100 * stepIndex) / stepCount}
      onClose={onClose}
      fullWidth={activeStep.fullWidth}
    >
      {replaceUrl || flowApi.skipEnabled ? (
        <FullScreenLoader />
      ) : (
        <Component
          booking={bookingData?.[0]}
          // @ts-ignore
          variant={activeStepVariant?.['flowVariant']}
          flow={fConfig.type}
          {...flowApi}
        />
      )}
    </Layout>
  )
}

export const getServerSideProps: GetServerSideProps = async ({ query: q }) => {
  // Extract only the values we need
  const {
    returnUrl,
    service,
    ilan = null,
    bookingId = null,
    agentId = null,
    registration = null,
    caseId = null,
    closeUrl = q.returnUrl,
    country = null,
    systemCode = SystemCode.MYLP,
    tenant = null,
  } = q

  const query = isQuery({ ...q, systemCode })
    ? omitNull({
        ilan,
        returnUrl,
        service,
        country,
        agentId,
        bookingId,
        caseId,
        systemCode,
        licensePlate: registration,
        closeUrl,
        tenant,
      })
    : null

  return { props: { query } }
}

export default Index
