import { Organization, SystemCode } from '@ngb-frontend/shared/types'

import type { QueryCountryCode } from '@ngb-frontend/shared/types'

export const countryCodeToOrg: Record<QueryCountryCode, `${Organization}`> = {
  LPUK: Organization.LP,
  GLOBAL: Organization.ALD, // universal
  '4001': Organization.ALD, // AU
  '5601': Organization.ALD, // BE
  '25001': Organization.ALD, // FR
  '25002': Organization.ALD, // FR
  '25003': Organization.ALD, // FR
  '48401': Organization.ALD, // MX
  '64201': Organization.ALD, // RO
  '75201': Organization.ALD, // SE
  '75202': Organization.ALD, // SE
  '75601': Organization.ALD, // CH
}

export const systemCodeToOrg: Record<`${SystemCode}`, `${Organization}`> = {
  [SystemCode.MYAY]: Organization.ALD,
  [SystemCode.SFALD]: Organization.ALD,
  [SystemCode.SFLP]: Organization.LP,
  [SystemCode.SPLP]: Organization.LP,
  [SystemCode.MYLP]: Organization.LP,
}
