import { Grid } from '@velocity/ui'
import React from 'react'
import { Form } from 'react-final-form'

import { useLocalization } from '@ngb-frontend/shared/context'
import { services } from '@ngb-frontend/shared/mocks'
import {
  MaintenanceAndRepair,
  type ServiceFormProps,
  type ServiceFormValues,
} from '@ngb-frontend/shared/types'
import { FormFooter, ServicesField } from '@ngb-frontend/shared/ui'
import { camelize, isLPSystem, useQuery } from '@ngb-frontend/shared/utils'

export const ServiceForm: React.FC<ServiceFormProps> = ({
  onSubmit,
  initialValues,
  variant,
  enabledServices,
}) => {
  const query = useQuery()
  const isSweden = useLocalization().locale.split('-')[1] === 'SE'
  const handleSubmit = React.useCallback(
    (values: ServiceFormValues) => {
      onSubmit?.({
        [camelize(variant) as keyof ServiceFormValues]: values.services || [],
      })
    },
    [onSubmit, variant],
  )

  return (
    <Form<ServiceFormValues>
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {(formProps) => {
        const filteredServices = isSweden
          ? Object.entries(enabledServices || []).reduce(
              (acc, [srv, isEnabled]) => {
                if (
                  ((srv === 'Repair' || srv === 'RegularMaintenance') &&
                    formProps.values.services?.includes('MOT')) ||
                  (srv === 'MOT' &&
                    (formProps.values.services?.includes('Repair') ||
                      formProps.values.services?.includes(
                        'RegularMaintenance',
                      )))
                ) {
                  return { ...acc, [srv]: false }
                } else return { ...acc, [srv]: isEnabled }
              },
              {},
            )
          : enabledServices

        return (
          <form
            onSubmit={formProps.handleSubmit}
            data-e2e-component="service-form"
          >
            <Grid>
              <Grid.Item LG={6}>
                <ServicesField
                  services={services[variant]}
                  variant={variant}
                  enabledServices={filteredServices}
                  hideLabel
                  showHint={
                    !isLPSystem(query?.systemCode) &&
                    variant === MaintenanceAndRepair.ExtraService &&
                    !!formProps.values.services?.length
                  }
                />
              </Grid.Item>
            </Grid>
            <FormFooter />
          </form>
        )
      }}
    </Form>
  )
}
